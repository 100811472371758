<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('公告管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <!-- <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  placeholder="网站名称" v-model="query.name" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col> -->
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="edit()">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </el-col>
    <el-col :span="12" style="text-align:right;">
      <el-button-group>
        <el-button type="success" @click="getFriendLinks()" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <column-filter @seleted="(cols)=>this.columns = cols" :table-refs="this.$refs.dataTable" 
          column-name="notice-filter"/>
      </el-button-group>
    </el-col>
  </el-row>
  
  <div class="table-wrap">
    <el-table :data="notices" style="width: 100%" :height="docHeight" size="mini" ref="dataTable" stripe>
      <el-table-column type="selection"  min-width="55" :fixed="!isMinScreen"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.id" prop="id" :label="msg('ID')" min-width="70" :fixed="!isMinScreen"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.content" prop="content" :label="msg('网站名')" min-width="120"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.created" prop="created" :label="msg('创建时间')" min-width="120"></el-table-column>
      <el-table-column v-if="columns['defaultShow'] || columns.seq" prop="seq" :label="msg('排序')" min-width="70"></el-table-column>
    </el-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page="currentPage"
      :page-sizes="[10, 20,50, 100, 200, 300, 400]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>


  <el-dialog :title="dglogTitle" :visible.sync="dialogEditVisible"  :fullscreen="isMinScreen">
    <notice-edit :notice="currNotice" v-on:closeDg="closeEditDialog" ref="friendLinkEdit"/>
  </el-dialog>

</div>
</template> 

<script>
// @ is an alias to /src
import NoticeEdit from "./NoticeEdit.vue";
import TableColumnFilter from "../../components/TableColumnFilter.vue";

export default {
  name: 'Notice',
  components: {
    NoticeEdit:NoticeEdit,
    ColumnFilter: TableColumnFilter
  },
  mounted:function(){
    var _this = this;
    this.columns = this.$kit.getColumns('notice-filter');
    _this.getNotice();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          name:null,
        },
        catsPopoverVisiable1: false,
        catsPopoverVisiable2: false,
        currentPage: 1,   //分页的当前页
        notices: [],   //表格数据列表
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        dialogEditVisible:false,
        dglogTitle:"",
        currNotice:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    getNotice: function(){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);

      this.axios.post(this.$kit.api.noticeList, param).then(function (result) {
        if(result.status){
          if(_this.notices.length>0)
            _this.notices.splice(0, _this.notices.length);
          
          _this.total = result.data.recordsTotal;
          var accountDatas = result.data.data;

          for(var i=0; i<accountDatas.length; i++){
            var item = accountDatas[i];

            _this.notices.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getNotice();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      this.query.length = val;
      this.query.start = 0;
      this.getNotice();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      this.query.start = this.query.length * (val -1);
      this.getNotice();
    },
    add(){  //添加
      this.dglogTitle = this.msg("添加公告");
      var _this = this;
      _this.currNotice = {
        id: null,
        content: null,
        seq:0
      };
     
      _this.dialogEditVisible = true;
    },
    edit(){
      var _this = this;
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      _this.currNotice = selectes[0];
      _this.dglogTitle = this.msg("编辑公告");
      _this.dialogEditVisible = true;

    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getNotice();
      }
      this.dialogEditVisible = false;
    },
    deleted(){  //删除
        var _this = this;
      var selectes = this.$refs.dataTable.selection;
      if(selectes.length==0){
        _this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var ids = [];
      for(var i = 0; i < selectes.length; i++){
          ids.push(selectes[i].id);
      }
      
      this.$confirm(this.msg('确定要删除该公告吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.noticeDelete, ids)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getNotice();
          });
        });
    },
    handleEditDgOpen:function(){
      var _this = this;
      _this.$nextTick(() => {
        _this.$refs.productEdit.initForm();
      });
    },
  }
}
</script>

<style class="scss" scoped>


</style>
