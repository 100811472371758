<template>
    <el-button type="info" size="mini" @click="openFilter()">
        <i class="iconfont" style="display:inline-block;margin-right:4px; font-size:12px;">&#xe627;</i>
        <transition name="el-fade-in-linear">
        <span v-show="!isMinScreen">{{msg('筛选')}}</span>
        </transition>
        <el-dialog :title="msg('显示过滤')" :visible.sync="dialogVisiable" :fullscreen="isMinScreen" :append-to-body="true"
                width="400px" @opened="openedDg()">
            <div>
                <el-table :data="originColumns" style="width: 100%" :height="300" size="mini" ref="filterTable" row-key>
                    <el-table-column type="selection"  min-width="55"></el-table-column>
                    <el-table-column prop="name" label="字段名" min-width="70"></el-table-column>
                </el-table>
                <div style="text-align:center; margin-top:15px; margin-bottom:15px;">
                    <el-button type="primary" size="mini"  @click="enter"><i class="el-icon-check"></i><span>确定</span></el-button>
                    <el-button type="success" size="mini"  @click="clear"><i class="el-icon-check"></i><span>清空筛选</span></el-button>
                    <el-button  size="mini" @click="dialogVisiable = false;">取消</el-button>
                </div>
            </div>
        </el-dialog>
    </el-button>
</template>

<script>
export default {
    name: "TableColumnFilter",
    data() {
        return {
            dialogVisiable: false,
            originColumns: [],
            columns: {},  //显示字段，索引
        };
    },
    computed: {
      isMinScreen: {  //是否小屏幕，浏览器
        get() {
                return this.$store.state.isMinScreen;
            }
        },
    },
    props: {
        tableRefs: Object,
        columnName: String,
    },
    mounted: function() {
        this.initFilter();
    },
    methods: {
        initFilter(){
            //初始化所有字段
          
            if(this.tableRefs && this.originColumns.length == 0 ){
                var full = localStorage.getItem(this.columnName+"-full");
                if(full){
                    this.originColumns = JSON.parse(full);
                }else{
                    var cols = this.tableRefs.columns;
                    for(let i=0; i<cols.length; i++){
                        var label = cols[i]["label"];
                        var prop = cols[i]["property"];
                        
                        if(!label){
                            label = this.fmt("第%s列", [i+1]);
                        }
                        if(!prop){
                            prop = "p"+i
                        }

                        this.originColumns.push({"name": label, prop:prop, index: i, "checked": true});
                        
                    }
                }
            }
            
            this.originColumns.forEach( o => this.columns[o.prop] = o.checked);

            //判断传进来的columns是不是空的，
            var columnsStr = localStorage.getItem(this.columnName);
            if(columnsStr){
                var saveCols = JSON.parse(columnsStr);
                this.columns = {};

                for(var key in saveCols){
                    this.columns[key] = saveCols[key];
                }

                this.setChecked();
            }else{
                this.originColumns.forEach(a => a.checked = true);
            }

        },
        openFilter(){
            this.initFilter();
            this.dialogVisiable = true;
        },
        openedDg(){
            var _this = this;
            this.originColumns.forEach(item => {
                if (item.checked) {
                    _this.$refs.filterTable.toggleRowSelection(item, true);
                }
            });  
        },
        enter(){
            var selectes = this.$refs.filterTable.selection;
            if(selectes.length != 0){

                this.originColumns.forEach(o => o.checked = false);
                for(var key in this.columns){
                    this.columns[key] = false;
                }

                selectes.forEach(s => {
                    var o = this.originColumns.find(o => s.index == o.index );
                    if(o) o.checked = true;

                    this.columns[o.prop] = true;
                });

                localStorage.setItem(this.columnName, JSON.stringify(this.columns));
                localStorage.setItem(this.columnName+"-full", JSON.stringify(this.originColumns));
            }

            this.$emit("seleted", this.columns);

            this.dialogVisiable= false;

        }, 
        clear(){
            this.originColumns.forEach(o => o.checked == true);
            for(var key in this.columns){
                this.columns[key] = false;
            }
            localStorage.removeItem(this.columnName);
            localStorage.removeItem(this.columnName+"-full");

            this.$emit("seleted", this.columns);

            this.dialogVisiable= false;
        }, 
        columnsLength(){
            let length = 0;
            if(this.columns){
                for(var key in this.columns){
                    ++length;
                }
            }
            return length;
        },
        setChecked(){
            for(let i=0; i<this.originColumns.length; i++){
                var col = this.originColumns[i];
                if(this.columns[col.prop]){
                    col.checked = true;
                }else{
                    col.checked = false;
                }
            }
        }

    }
};
</script>

<style class="scss" scoped>
</style>
